import { ReadLines } from '../engine/import/importerutils.js';
import { AddDiv, CreateDomElement } from '../engine/viewer/domutils.js';
import { ButtonDialog } from './dialog.js';
import { Loc } from '../engine/core/localization.js';

export function ShowOpenUrlDialog (onOk)
{
    let dialog = new ButtonDialog ();
    let urlsTextArea = CreateDomElement ('textarea', 'ov_dialog_textarea');
    let contentDiv = dialog.Init (Loc ('从 url 打开'), [
        {
            name : Loc ('取消'),
            subClass : 'outline',
            onClick () {
                dialog.Close ();
            }
        },
        {
            name : Loc ('确定'),
            onClick () {
                let urls = [];
                ReadLines (urlsTextArea.value, (line) => {
                    urls.push (line);
                });
                dialog.Close ();
                onOk (urls);
            }
        }
    ]);
    let text = Loc ('在这里，您可以根据模型的 url 加载模型。如果模型由多个文件构建而成，则可以添加更多行。');
    AddDiv (contentDiv, 'ov_dialog_section', text);
    contentDiv.appendChild (urlsTextArea);
    dialog.Open ();
    urlsTextArea.focus ();
    return dialog;
}
